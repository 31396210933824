.containerAsesoresComponent {
  background-color: rgb(255 255 255);
  padding: 20px !important;
}

.tituloAsesoresComponent {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  text-align: center !important;
  padding: 20px 0px 10px 0px !important;
}

.subtituloAsesoresComponent {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  text-align: center !important;
  padding: 0px 0px 20px 0px !important;
}

.kuula {
  width: 50% !important;
  height: 500px !important;
  border: unset !important;
  margin: 10px auto !important;
}

.videoAsesoresComponent {
  margin: auto !important;
  width: 50% !important;
  padding: 30px !important;
}

.beneficioAsesoresComponent {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  text-align: left !important;
}

@media only screen and (max-width: 600px) {
  .videoAsesoresComponent {
    padding: unset !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .videoAsesoresComponent {
    width: 100% !important;
  }
}
