.root {
  flex-grow: 1 !important;
}

.containerProyectosComponent {
  background: rgb(246 245 240) !important;
}

.tituloProyectosComponent {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  text-align: center !important;
  padding: 20px 0px 10px 0px !important;
}

.subtituloProyectosComponent {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  text-align: center !important;
  padding: 0px 0px 20px 0px !important;
}

.avatar {
  width: 150px !important;
  height: 150px !important;
  margin: auto !important;
  border: 5px solid rgb(74 1 125) !important;
}

.nombreProyecto {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  text-align: center !important;
  padding: 10px !important;
}
