.gridPromocionesPromocionesProyectoComponent {
  background: rgb(246 245 240) !important;
}

.tituloPromocionesProyectoComponent {
  text-align: center !important;
  padding: 20px !important;
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
}

.carousel {
  width: 80% !important;
  margin: 20px auto 20px auto !important;
}

.carousel button {
  background: rgb(255 170 0) !important;
}

.divImagenesPromocionPromocionesProyectoComponent {
  text-align: center !important;
  width: 80% !important;
  margin: auto !important;
  padding: 20px 100px 20px 100px !important;
}
.imagenPromocionPromocionesProyectoComponent {
  width: 50% !important;
  height: 500px !important;
  border-radius: 20px !important;
  margin: auto !important;
}

@media only screen and (max-width: 600px) {
  .carousel {
    width: 100% !important;
  }
  .divImagenesPromocionPromocionesProyectoComponent {
    padding: 20px !important;
  }
  .imagenPromocionPromocionesProyectoComponent {
    width: 100% !important;
    height: 400px !important;
    border-radius: 20px !important;
    margin: auto !important;
  }
}
@media only screen and (max-width: 900px) and (orientation: landscape) {
  .carousel {
    width: 100% !important;
  }
  .divImagenesPromocionPromocionesProyectoComponent {
    padding: 20px !important;
  }
  .imagenPromocionPromocionesProyectoComponent {
    width: 100% !important;
    height: 100% !important;
    border-radius: 20px !important;
    margin: auto !important;
  }
}
