.gridTituloContactanosPage {
  padding: 100px 0px 150px 150px !important;
}

.tituloContactanosPage {
  font-family: inherit !important;
  color: #ffffff !important;
  font-weight: bold !important;
}

.gridSubtituloContactanosPage {
  padding: 150px 115px 150px 0px !important;
}

.subtituloContactanosPage {
  font-family: inherit !important;
  color: #ffffff !important;
}

.gridHorarioContactanosPage {
  padding: 100px 60px 30px 60px !important;
}

.gridDatosContactanosPage {
  padding: 100px 60px 30px 60px !important;
}

.tituloAzulContactanosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
  margin: auto auto 20px auto !important;
}

.horarioContactanosPage {
  font-family: inherit !important;
  color: rgb(0 0 0) !important;
  margin: auto auto 20px auto !important;
}

.facebookIconContactanosPage {
  font-size: 50px !important;
  color: rgb(74 1 125) !important;
  margin: 10px 10px auto auto !important;
}

.instagramIconContactanosPage {
  font-size: 50px !important;
  color: rgb(255 170 0) !important;
  margin: 10px 10px auto 10px !important;
}

.roomIconContactanosPage {
  font-size: 30px !important;
  color: rgb(255 170 0) !important;
  margin: auto 5px !important;
}

.phoneIconContactanosPage {
  font-size: 30px !important;
  color: rgb(255 170 0) !important;
  margin: auto 5px !important;
}

.mailIconContactanosPage {
  font-size: 30px !important;
  color: rgb(255 170 0) !important;
  margin: auto 5px !important;
}

.datosContactoContactanosPage {
  display: flex !important;
  margin: 10px 5px !important;
  font-family: inherit !important;
  color: rgb(0 0 0) !important;
}

.containerContactanosPage {
  padding: 70px 200px 70px 200px !important;
}

.mapaContactanosPage {
  border: none !important;
  border-radius: 0px 15px 15px 0px !important;
  width: 100% !important;
  margin: auto !important;
}

.gridFormularioContactanosPage {
  /* background: red !important; */
  background: rgb(248 247 242) !important;
  padding: 50px 50px 10px 50px !important;
  border-radius: 15px 0px 0px 15px !important;
}

.mensajeFormularioContactanosPage {
  font-family: inherit !important;
  color: rgb(0 0 0) !important;
  font-size: 18px !important;
}

.labelFormularioContactanosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
}

.inputFormularioContactanosPage {
  background: rgb(255 255 255) !important;
  border-radius: 10px !important;
  width: 100% !important;
}

.ant-select-selector {
  background: rgb(255 255 255) !important;
  border-radius: 10px !important;
  width: 100% !important;
  height: 40px !important;
  border: unset !important;
}

.btn_enviar {
  background-color: rgb(255 170 0) !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
  cursor: pointer !important;
  border-radius: 10px !important;
  width: 150px !important;
  margin: 15px 0px 15px 0px !important;
}

.btn_enviar:hover {
  background-color: rgb(74 1 125) !important;
  color: rgb(255 170 0) !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

@media only screen and (max-width: 600px) {
  .gridTituloContactanosPage {
    padding: 50px 20px 20px 20px !important;
  }
  .gridSubtituloContactanosPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .gridHorarioContactanosPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .gridDatosContactanosPage {
    padding: 20px !important;
  }
  .tituloAzulContactanosPage {
    text-align: center !important;
  }
  .containerContactanosPage {
    padding: 20px !important;
  }
  .mapaContactanosPage {
    border-radius: 15px !important;
    margin: 10px auto !important;
  }
  .gridFormularioContactanosPage {
    /* background: red !important; */
    background: rgb(248 247 242) !important;
    padding: 20px !important;
    border-radius: 15px !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .gridTituloContactanosPage {
    padding: 50px 20px 20px 20px !important;
  }
  .gridSubtituloContactanosPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .gridHorarioContactanosPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .gridDatosContactanosPage {
    padding: 20px !important;
  }
  .tituloAzulContactanosPage {
    text-align: center !important;
  }
  .containerContactanosPage {
    padding: 20px !important;
  }
  .mapaContactanosPage {
    border-radius: 15px !important;
    margin: 10px auto !important;
  }
  .gridFormularioContactanosPage {
    /* background: red !important; */
    background: rgb(248 247 242) !important;
    padding: 20px !important;
    border-radius: 15px !important;
  }
}
