.containerInicioInicioComponent {
  padding-top: 50px !important;
}

.title1InicioComponent {
  margin: 0px 15px 0px 15px !important;
  font-family: unset !important;
  color: #ffffff !important;
}

.title2InicioComponent {
  margin: 0px 15px 0px 15px !important;
  font-family: inherit !important;
  color: #ffffff !important;
  font-weight: bold !important;
}

.somosInicioComponent {
  font-family: inherit !important;
  color: #ffffff !important;
  text-align: left !important;
}

.facebookIconInicioComponent {
  color: rgb(255 170 0) !important;
  margin: 2px 5px 2px 5px !important;
  font-size: xx-large !important;
}

.instagramIconInicioComponent {
  color: rgb(255 170 0) !important;
  margin: 2px 5px 2px 5px !important;
  font-size: xx-large !important;
}

.roomIconInicioComponent {
  color: rgb(255 170 0) !important;
  margin: 2px 5px 2px 5px !important;
  font-size: xx-large !important;
}

.direccionInicioComponent {
  font-family: unset !important;
  color: #ffffff !important;
  text-align: left !important;
  display: flex !important;
}

a {
  text-decoration: none !important;
  color: #ffffff !important;
}

.containerSomosInicioComponent {
  padding: 10px 55px 10px 70px !important;
  text-align: left !important;
}

.containerBottomInicioComponent {
  padding: 30px 100px 10px 100px !important;
}

.gridIconsInicioComponent {
  text-align: left !important;
}

.gridDirectionInicioComponent {
  text-align: left !important;
}

.gridTitle1InicioComponent {
  padding: 10px 55px 10px 70px !important;
  text-align: left !important;
}

.gridTitle2InicioComponent {
  padding: 10px 55px 10px 70px !important;
  text-align: left !important;
}

@media only screen and (max-width: 600px) {
  .title1InicioComponent {
    padding: 60px 0px 0px 0px !important;
    text-align: center !important;
  }
  .somosInicioComponent {
    text-align: center !important;
  }
  .containerSomosInicioComponent {
    padding: 20px !important;
    text-align: center !important;
  }
  .containerBottomInicioComponent {
    padding: 20px !important;
  }
  .gridIconsInicioComponent {
    text-align: center !important;
  }
  .gridDirectionInicioComponent {
    text-align: center !important;
  }
  .gridTitle1InicioComponent {
    padding: 10px !important;
    text-align: center !important;
  }
  .title2InicioComponent {
    text-align: center !important;
    padding: 5px !important;
    margin: auto !important;
  }
  .gridTitle2InicioComponent {
    padding: 10px !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .title1InicioComponent {
    padding: 60px 0px 0px 0px !important;
    text-align: center !important;
  }
  .containerBottomInicioComponent {
    padding: 10px !important;
  }
  .gridTitle1InicioComponent {
    padding: 10px !important;
    text-align: center !important;
  }
  .title2InicioComponent {
    text-align: center !important;
    padding: 5px !important;
    margin: auto !important;
  }
  .gridTitle2InicioComponent {
    padding: 10px !important;
  }
}
