.containerNosotrosComponent {
  background-color: rgb(255 255 255) !important;
}

.tituloNosotrosComponent {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  text-align: left !important;
  padding: 20px 0px 20px 0px !important;
}

.subtituloNosotrosComponent {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  text-align: left !important;
  padding: 0px 0px 20px 0px !important;
}

.textoNosotrosComponent {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  text-align: left !important;
}

.containerTextoNosotrosComponent {
  padding: 70px 0px 0px 70px !important;
}

.containerImagenesNosotrosComponent {
  padding: 20px !important;
}

.containerImagenChicaNosotrosComponent {
  vertical-align: bottom !important;
  display: grid !important;
  align-items: end !important;
  justify-content: end !important;
}

.avatarNosotrosComponent {
  background: rgb(70 136 100) !important;
  width: 70px !important;
  height: 70px !important;
  margin: auto !important;
}

.iconNosotrosComponent {
  font-size: 50px !important;
}

.containerIconsNosotrosComponent {
  padding: 60px 30px 40px 30px !important;
}

.preguntaNosotrosComponent {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
}

.respuestaNosotrosComponent {
  font-family: inherit !important;
}
.cardNosotrosComponent {
  box-shadow: unset !important;
}

@media only screen and (max-width: 600px) {
  .textoNosotrosComponent {
    text-align: center !important;
  }
  .containerTextoNosotrosComponent {
    padding: 20px !important;
    text-align: center !important;
  }
  .containerImagenesNosotrosComponent {
    display: none !important;
  }
  .containerIconsNosotrosComponent {
    padding: 30px 30px 40px 30px !important;
  }

  .cardHeaderNosotrosComponent {
    text-align: center !important;
  }
  .tituloNosotrosComponent {
    text-align: center !important;
  }
  .subtituloNosotrosComponent {
    text-align: center !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .textoNosotrosComponent {
    text-align: center !important;
  }
  .containerTextoNosotrosComponent {
    padding: 20px !important;
    text-align: center !important;
  }
  .containerImagenesNosotrosComponent {
    display: none !important;
  }
  .containerIconsNosotrosComponent {
    padding: 30px 30px 40px 30px !important;
  }

  .cardHeaderNosotrosComponent {
    text-align: center !important;
  }
  .tituloNosotrosComponent {
    text-align: center !important;
  }
  .subtituloNosotrosComponent {
    text-align: center !important;
  }
}
