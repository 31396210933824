@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
body {
  font-family: "Montserrat", sans-serif !important;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background: rgb(130 30 115);
}
::-webkit-scrollbar-track {
  background: #e1e1e1;
}
/* -------------------------------------------------------------- */

/* Botón amarillo */
.btn_amarillo {
  background-color: rgb(255 170 0) !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
  text-transform: initial !important;
  font-family: unset !important;
  font-size: large !important;
  letter-spacing: 1.5px !important;
  border-radius: 10px !important;
  width: 150px !important;
  margin: 15px 0px 15px 0px !important;
}

.btn_amarillo:hover {
  background-color: rgb(74 1 125) !important;
  color: rgb(255 170 0) !important;
}

input {
  font-family: "Montserrat", sans-serif !important;
}

.ant-select-arrow {
  color: #670495 !important;
}
