._404NotFound {
  text-align: center !important;
  margin: 100px 0px 0px 0px !important;
  font-size: 10rem !important;
  text-shadow: 3px 3px 0px rgb(15 210 161 1) !important;
  font-family: inherit !important;
}

.subtituloPageNotFound {
  text-align: center !important;
  margin: 0px 0px 20px 0px !important;
  font-family: inherit !important;
}

.textoPageNotFound {
  text-align: center !important;
  margin: 0px 0px 50px 0px !important;
  font-family: inherit !important;
}

.btnInicioPageNotFound {
  border: 1px solid rgb(0 0 0 1) !important;
  width: 250px !important;
  margin: auto !important;
  text-align: center !important;
  cursor: pointer !important;
  box-shadow: 3px 3px 0px 0px rgb(15 210 161 1);
  -webkit-box-shadow: 3px 3px 0px 0px rgb(15 210 161 1);
  -moz-box-shadow: 3px 3px 0px 0px rgb(15 210 161 1);
  font-family: inherit !important;
}

.divIconsPageNotFound {
  padding: 15px !important;
  margin: 15px auto 15px auto !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: inherit !important;
}

.divIconPageNotFound {
  width: 45px !important;
  height: 45px !important;
  border: 1px solid rgb(0 0 0 1) !important;
  margin: 5px 10px 5px 10px !important;
  padding: 5px !important;
  box-shadow: 3px 3px 0px 0px rgb(15 210 161 1);
  -webkit-box-shadow: 3px 3px 0px 0px rgb(15 210 161 1);
  -moz-box-shadow: 3px 3px 0px 0px rgb(15 210 161 1);
  font-family: inherit !important;
}

.iconPageNotFound {
  color: rgb(0 0 0 1) !important;
  font-family: inherit !important;
}

.aPageNotFound {
  text-decoration: none !important;
  color: rgb(0 0 0 1) !important;
  font-family: inherit !important;
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  ._404NotFound {
    margin: 50px 0px 0px 0px !important;
  }

  .btnInicioPageNotFound {
    margin: auto auto 50px auto !important;
  }
}
