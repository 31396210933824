.gridTituloNosotrosPage {
  padding: 100px 0px 150px 150px !important;
}
.gridSomosNosotrosPage {
  padding: 150px 115px 150px 0px !important;
}

.titleNosotrosPage {
  font-family: inherit !important;
  color: rgb(255 255 255) !important;
  font-weight: bold !important;
}

.subtitleNosotrosPage {
  font-family: inherit !important;
  color: rgb(255 255 255) !important;
}

.preguntaNosotrosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
  text-align: center !important;
  padding: 25px 0px 25px 0px !important;
}

.titleAzulNosotrosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
  text-align: center !important;
}

.somosNosotrosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  text-align: center !important;
  padding: 50px !important;
}

.slideNosotrosNosotrosPage {
  width: 60% !important;
  border-radius: 15px !important;
  box-shadow: 6px 8px 13px -6px rgb(0 0 0 / 75%) !important;
  -webkit-box-shadow: 6px 8px 13px -6px rgb(0 0 0 / 75%) !important;
  -moz-box-shadow: 6px 8px 13px -6px rgba(0 0 0 0.75) !important;
}

.gridMisionVisionTituloNosotrosPage {
  padding: 0px 0px 0px 180px !important;
}

.misionVisionTituloNosotrosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
  padding: 25px 0px 25px 0px !important;
}

.gridMisionVisionNosotrosPage {
  padding: 50px 150px 20px 100px !important;
}

.misionNosotrosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  padding: 0px 0px 20px 0px !important;
}

.visionNosotrosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
}

.gridPropuestaValorNosotrosPage {
  background-color: rgb(246 245 240) !important;
}

.logoAzulVerticalNosotrosPage {
  width: 50% !important;
  padding-top: 50px !important;
}

.nuestrosProyectosTituloNosotrosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
  text-align: center !important;
  padding: 50px !important;
}

.avatarNosotrosPage {
  width: 150px !important;
  height: 150px !important;
  margin: auto !important;
  border: 5px solid rgb(74 1 125) !important;
}

.nombreProyectoNosotrosPage {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  text-align: center !important;
  padding: 10px !important;
}

.descripcionProyectoNosotrosPage {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  text-align: center !important;
  padding: 10px !important;
  max-height: 300px !important;
  min-height: 100px !important;
}

.aNosotrosPage {
  text-decoration: none !important;
  color: rgb(255 255 255) !important;
}
.facebookIconNosotrosPage {
  color: rgb(255 170 0) !important;
  margin: 2px 5px 2px 5px !important;
  font-size: xx-large !important;
}

.instagramIconNosotrosPage {
  color: rgb(255 170 0) !important;
  margin: 2px 5px 2px 5px !important;
  font-size: xx-large !important;
}

@media only screen and (max-width: 600px) {
  .gridTituloNosotrosPage {
    padding: 50px 20px 20px 20px !important;
  }
  .gridSomosNosotrosPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .slideNosotrosNosotrosPage {
    width: 100% !important;
  }
  .gridMisionVisionTituloNosotrosPage {
    text-align: center !important;
    padding: 0px !important;
  }
  .misionVisionTituloNosotrosPage {
    padding: 20px !important;
  }
  .gridMisionVisionNosotrosPage {
    padding: 20px !important;
    text-align: center !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .gridTituloNosotrosPage {
    padding: 50px 20px 20px 20px !important;
  }
  .gridSomosNosotrosPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .slideNosotrosNosotrosPage {
    width: 100% !important;
  }
  .gridMisionVisionTituloNosotrosPage {
    text-align: center !important;
    padding: 0px !important;
  }
  .misionVisionTituloNosotrosPage {
    padding: 20px !important;
  }
  .gridMisionVisionNosotrosPage {
    padding: 20px !important;
    text-align: center !important;
  }
}
