.containerPromocionesInicioComponent {
  background-color: rgb(246 245 240) !important;
  padding: 20px !important;
}

.tituloPromocionesInicioComponent {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  text-align: left !important;
  padding: 20px 0px 10px 0px !important;
}

.subtituloPromocionesInicioComponent {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  text-align: left !important;
  padding: 0px 0px 20px 0px !important;
}

.containerTextoPromocionesInicioComponent {
  padding: 20px 70px !important;
}

.ofertaPromocionesInicioComponent {
  width: 350px !important;
  height: 350px !important;
  border-radius: 20px !important;
  box-shadow: 6px 8px 13px -6px rgb(0 0 0 / 75%) !important;
  -webkit-box-shadow: 6px 8px 13px -6px rgb(0 0 0 / 75%) !important;
  -moz-box-shadow: 6px 8px 13px -6px rgba(0, 0, 0, 0.75) !important;
}

@media only screen and (max-width: 600px) {
  .ofertaPromocionesInicioComponent {
    margin: 10px !important;
    width: 250px !important;
    height: 250px !important;
  }
  .tituloPromocionesInicioComponent {
    text-align: center !important;
  }
  .subtituloPromocionesInicioComponent {
    text-align: center !important;
  }
  .containerTextoPromocionesInicioComponent {
    padding: 20px !important;
  }
  .gridBotonAmarilloPromocionesInicioComponent {
    text-align: center !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .ofertaPromocionesInicioComponent {
    margin: 10px !important;
    width: 250px !important;
    height: 250px !important;
  }
  .tituloPromocionesInicioComponent {
    text-align: center !important;
  }
  .subtituloPromocionesInicioComponent {
    text-align: center !important;
  }
  .containerTextoPromocionesInicioComponent {
    padding: 20px !important;
  }
  .gridBotonAmarilloPromocionesInicioComponent {
    text-align: center !important;
  }
}
