@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap);
.gridTituloAsesoresPage {
  padding: 100px 0px 150px 150px !important;
}

.tituloAsesoresPage {
  font-family: inherit !important;
  color: rgb(255 255 255);
  font-weight: bold !important;
}

.gridSubtituloAsesoresPage {
  padding: 150px 115px 150px 0px !important;
}

.subtituloAsesoresPage {
  font-family: inherit !important;
  color: rgb(255 255 255) !important;
}

.gridMotivacionTituloAsesoresPage {
  padding: 0px 0px 0px 180px !important;
}

.motivacionTituloAsesoresPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
  padding: 25px 0px 25px 0px !important;
}

.gridMotivacionAsesoresPage {
  padding: 50px 150px 20px 100px !important;
}

.motivacionAsesoresPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  padding: 0px 0px 20px 0px !important;
}

.containerIconsAsesoresPage {
  padding: 20px 100px 20px 100px;
}

.cardAsesoresPage {
  box-shadow: unset !important;
}

.iconAsesoresPage {
  width: 100px !important;
}

.nombreIconAsesoresPage {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
}

.containerAsesorAsesoresPage {
  width: 50% !important;
  margin: auto !important;
  padding: 20px !important;
}

.gridFotoAsesorAsesoresPage {
  padding: 0px 20px 0px 20px !important;
}

.fotoAsesorAsesoresPage {
  width: 100% !important;
  border-radius: 15px !important;
}

.nombreAsesorAsesoresPage {
  font-family: inherit !important;
  font-weight: bold !important;
  color: rgb(74 1 125) !important;
}

.cargoAsesorAsesoresPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
}

.proyectoAsignadoAsesorAsesoresPage {
  font-family: inherit !important;
  font-weight: bold !important;
  color: rgb(74 1 125) !important;
}

.datosContactoAsesorAsesoresPage {
  font-family: inherit !important;
  font-weight: bold !important;
  color: rgb(74 1 125) !important;
  font-size: 15px !important;
  display: flex !important;
}

.iconCelularAsesoresPage {
  color: rgb(255 170 0) !important;
  margin: 2px 5px 2px 5px !important;
  font-size: x-large !important;
}

.iconEmailAsesoresPage {
  color: rgb(130 30 115) !important;
  margin: 2px 5px 2px 5px !important;
  font-size: x-large !important;
}
.iconWhatsAppAsesoresPage {
  color: rgb(100 177 97) !important;
  margin: 2px 5px 2px 5px !important;
  font-size: x-large !important;
}

.aAsesoresPage {
  text-decoration: none !important;
  color: rgb(74 1 125) !important;
  display: flex !important;
}

.containerFinalAsesoresPage {
  background: rgb(246 245 240);
}

.gridTextoFinalAsesoresPage {
  padding: 50px 20px 20px 200px !important;
}

.textoFinalAsesoresPage {
  font-family: inherit !important;
  font-weight: bold !important;
  color: rgb(74 1 125) !important;
}

.imageFinalAsesoresPage {
  width: 80% !important;
  margin: auto !important;
  border-radius: 15px !important;
}

.containerImageFinalAsesoresPage {
  text-align: center !important;
  padding: 50px !important;
}

@media only screen and (max-width: 600px) {
  .gridTituloAsesoresPage {
    padding: 50px 20px 20px 20px !important;
  }
  .gridSubtituloAsesoresPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .gridMotivacionTituloAsesoresPage {
    text-align: center !important;
    padding: 0px !important;
  }
  .motivacionTituloAsesoresPage {
    padding: 20px !important;
  }
  .gridMotivacionAsesoresPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .containerIconsAsesoresPage {
    padding: 5px !important;
  }
  .cardHeaderAsesoresPage {
    text-align: center !important;
  }
  .containerAsesorAsesoresPage {
    width: 100% !important;
    margin: auto !important;
    padding: 20px !important;
  }
  .gridFotoAsesorAsesoresPage {
    padding: 0px !important;
  }
  .gridDatosAsesorAsesoresPage {
    text-align: center !important;
  }
  .containerFinalAsesoresPage {
    padding: 20px !important;
  }
  .gridTextoFinalAsesoresPage {
    padding: 10px !important;
    text-align: center !important;
  }
  .containerImageFinalAsesoresPage {
    padding: 5px !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .gridTituloAsesoresPage {
    padding: 50px 20px 20px 20px !important;
  }
  .gridSubtituloAsesoresPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .gridMotivacionTituloAsesoresPage {
    text-align: center !important;
    padding: 0px !important;
  }
  .motivacionTituloAsesoresPage {
    padding: 20px !important;
  }
  .gridMotivacionAsesoresPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .containerIconsAsesoresPage {
    padding: 20px 50px 20px 50px !important;
  }
  .cardHeaderAsesoresPage {
    text-align: center !important;
  }
  .containerAsesorAsesoresPage {
    width: 100% !important;
    margin: auto !important;
    padding: 20px !important;
  }
  .gridFotoAsesorAsesoresPage {
    padding: 0px !important;
  }
  .gridDatosAsesorAsesoresPage {
    text-align: center !important;
  }
  .containerFinalAsesoresPage {
    padding: 20px !important;
  }
  .gridTextoFinalAsesoresPage {
    padding: 10px !important;
    text-align: center !important;
  }
  .containerImageFinalAsesoresPage {
    padding: 5px !important;
  }
}

.grow {
  flex-grow: 1;
}

.appbar {
  background-color: transparent !important;
  box-shadow: unset !important;
  padding: 20px 50px 20px 200px !important;
}

.logo {
  width: 20% !important;
}

.item {
  margin: 0px 15px 0px 15px !important;
  font-family: unset !important;
}
a {
  text-decoration: none !important;
  color: #ffffff !important;
}

.aMovil {
  text-decoration: none !important;
  color: #000000 !important;
  font-weight: bold !important;
  font-family: Montserrat !important;
}

.number {
  color: rgb(255 170 0) !important;
  margin: 0px 15px 0px 15px !important;
  font-family: unset !important;
  font-weight: bold !important;
}

@media only screen and (max-width: 600px) {
  .appbar {
    padding: unset !important;
    position: fixed !important;
    background-color: rgb(0 0 0 / 80%) !important;
  }
  .logo {
    width: 10% !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .appbar {
    padding: unset !important;
    position: fixed !important;
    background-color: rgb(0 0 0 / 80%) !important;
  }
}

.containerFooterComponent {
  background-color: rgb(74 1 125) !important;
  padding: 25px 50px 0px 200px !important;
}

.logoFooterComponent {
  width: 300px !important;
  margin: 15px !important;
}

.datosFooterComponent {
  font-family: inherit !important;
  color: rgb(255 255 255) !important;
  display: flex !important;
  font-weight: bold !important;
  font-size: 15px !important;
  align-items: center !important;
}

.tituloFooterComponent {
  font-family: inherit !important;
  color: rgb(255 255 255) !important;
  text-align: left !important;
  display: flex !important;
  font-weight: bold !important;
  padding-bottom: 40px !important;
}
 
.iconsFooterComponent {
  color: rgb(255 170 0) !important;
  margin: 2px 5px 2px 5px !important;
  font-size: xx-large !important;
}

.aFooterComponent {
  text-decoration: none !important;
  color: rgb(255 255 255);
}

.enlacesFooterComponent {
  font-size: 18px !important;
  color: rgb(255 255 255) !important;
  font-family: inherit !important;
}

.containerIzquierdaFooterComponent {
  padding: 20px !important;
}

.containerCentroFooterComponent {
  padding: 40px 0px !important;
}

.containerDerechaFooterComponent {
  padding: 40px 0px !important;
}

.libroFooterComponent {
  width: 100% !important;
}

.libroTextoFooterComponent {
  font-family: inherit !important;
  color: rgb(225 255 255) !important;
  text-align: center !important;
  display: flex !important;
  font-weight: bold !important;
  font-size: 12px !important;
}

.leyFooterComponent {
  font-family: inherit !important;
  text-align: center !important;
  display: flex !important;
  font-size: 10px !important;
  color: rgb(255 170 0) !important;
}

.containerLibroFooterComponent {
  vertical-align: bottom !important;
  display: grid !important;
  align-items: end !important;
  justify-content: end !important;
  padding-bottom: 45px !important;
}

@media only screen and (max-width: 600px) {
  .containerFooterComponent {
    padding: 10px !important;
  }
  .logoFooterComponent {
    width: 90% !important;
  }
  .libroFooterComponent {
    width: 50% !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .containerFooterComponent {
    padding: 10px !important;
  }
  .logoFooterComponent {
    width: 90% !important;
  }
  .libroFooterComponent {
    width: 50% !important;
  }
}

.gridTituloContactanosPage {
  padding: 100px 0px 150px 150px !important;
}

.tituloContactanosPage {
  font-family: inherit !important;
  color: #ffffff !important;
  font-weight: bold !important;
}

.gridSubtituloContactanosPage {
  padding: 150px 115px 150px 0px !important;
}

.subtituloContactanosPage {
  font-family: inherit !important;
  color: #ffffff !important;
}

.gridHorarioContactanosPage {
  padding: 100px 60px 30px 60px !important;
}

.gridDatosContactanosPage {
  padding: 100px 60px 30px 60px !important;
}

.tituloAzulContactanosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
  margin: auto auto 20px auto !important;
}

.horarioContactanosPage {
  font-family: inherit !important;
  color: rgb(0 0 0) !important;
  margin: auto auto 20px auto !important;
}

.facebookIconContactanosPage {
  font-size: 50px !important;
  color: rgb(74 1 125) !important;
  margin: 10px 10px auto auto !important;
}

.instagramIconContactanosPage {
  font-size: 50px !important;
  color: rgb(255 170 0) !important;
  margin: 10px 10px auto 10px !important;
}

.roomIconContactanosPage {
  font-size: 30px !important;
  color: rgb(255 170 0) !important;
  margin: auto 5px !important;
}

.phoneIconContactanosPage {
  font-size: 30px !important;
  color: rgb(255 170 0) !important;
  margin: auto 5px !important;
}

.mailIconContactanosPage {
  font-size: 30px !important;
  color: rgb(255 170 0) !important;
  margin: auto 5px !important;
}

.datosContactoContactanosPage {
  display: flex !important;
  margin: 10px 5px !important;
  font-family: inherit !important;
  color: rgb(0 0 0) !important;
}

.containerContactanosPage {
  padding: 70px 200px 70px 200px !important;
}

.mapaContactanosPage {
  border: none !important;
  border-radius: 0px 15px 15px 0px !important;
  width: 100% !important;
  margin: auto !important;
}

.gridFormularioContactanosPage {
  /* background: red !important; */
  background: rgb(248 247 242) !important;
  padding: 50px 50px 10px 50px !important;
  border-radius: 15px 0px 0px 15px !important;
}

.mensajeFormularioContactanosPage {
  font-family: inherit !important;
  color: rgb(0 0 0) !important;
  font-size: 18px !important;
}

.labelFormularioContactanosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
}

.inputFormularioContactanosPage {
  background: rgb(255 255 255) !important;
  border-radius: 10px !important;
  width: 100% !important;
}

.ant-select-selector {
  background: rgb(255 255 255) !important;
  border-radius: 10px !important;
  width: 100% !important;
  height: 40px !important;
  border: unset !important;
}

.btn_enviar {
  background-color: rgb(255 170 0) !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
  cursor: pointer !important;
  border-radius: 10px !important;
  width: 150px !important;
  margin: 15px 0px 15px 0px !important;
}

.btn_enviar:hover {
  background-color: rgb(74 1 125) !important;
  color: rgb(255 170 0) !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

@media only screen and (max-width: 600px) {
  .gridTituloContactanosPage {
    padding: 50px 20px 20px 20px !important;
  }
  .gridSubtituloContactanosPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .gridHorarioContactanosPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .gridDatosContactanosPage {
    padding: 20px !important;
  }
  .tituloAzulContactanosPage {
    text-align: center !important;
  }
  .containerContactanosPage {
    padding: 20px !important;
  }
  .mapaContactanosPage {
    border-radius: 15px !important;
    margin: 10px auto !important;
  }
  .gridFormularioContactanosPage {
    /* background: red !important; */
    background: rgb(248 247 242) !important;
    padding: 20px !important;
    border-radius: 15px !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .gridTituloContactanosPage {
    padding: 50px 20px 20px 20px !important;
  }
  .gridSubtituloContactanosPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .gridHorarioContactanosPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .gridDatosContactanosPage {
    padding: 20px !important;
  }
  .tituloAzulContactanosPage {
    text-align: center !important;
  }
  .containerContactanosPage {
    padding: 20px !important;
  }
  .mapaContactanosPage {
    border-radius: 15px !important;
    margin: 10px auto !important;
  }
  .gridFormularioContactanosPage {
    /* background: red !important; */
    background: rgb(248 247 242) !important;
    padding: 20px !important;
    border-radius: 15px !important;
  }
}

.containerAsesoresComponent {
  background-color: rgb(255 255 255);
  padding: 20px !important;
}

.tituloAsesoresComponent {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  text-align: center !important;
  padding: 20px 0px 10px 0px !important;
}

.subtituloAsesoresComponent {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  text-align: center !important;
  padding: 0px 0px 20px 0px !important;
}

.kuula {
  width: 50% !important;
  height: 500px !important;
  border: unset !important;
  margin: 10px auto !important;
}

.videoAsesoresComponent {
  margin: auto !important;
  width: 50% !important;
  padding: 30px !important;
}

.beneficioAsesoresComponent {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  text-align: left !important;
}

@media only screen and (max-width: 600px) {
  .videoAsesoresComponent {
    padding: unset !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .videoAsesoresComponent {
    width: 100% !important;
  }
}

.containerInicioInicioComponent {
  padding-top: 50px !important;
}

.title1InicioComponent {
  margin: 0px 15px 0px 15px !important;
  font-family: unset !important;
  color: #ffffff !important;
}

.title2InicioComponent {
  margin: 0px 15px 0px 15px !important;
  font-family: inherit !important;
  color: #ffffff !important;
  font-weight: bold !important;
}

.somosInicioComponent {
  font-family: inherit !important;
  color: #ffffff !important;
  text-align: left !important;
}

.facebookIconInicioComponent {
  color: rgb(255 170 0) !important;
  margin: 2px 5px 2px 5px !important;
  font-size: xx-large !important;
}

.instagramIconInicioComponent {
  color: rgb(255 170 0) !important;
  margin: 2px 5px 2px 5px !important;
  font-size: xx-large !important;
}

.roomIconInicioComponent {
  color: rgb(255 170 0) !important;
  margin: 2px 5px 2px 5px !important;
  font-size: xx-large !important;
}

.direccionInicioComponent {
  font-family: unset !important;
  color: #ffffff !important;
  text-align: left !important;
  display: flex !important;
}

a {
  text-decoration: none !important;
  color: #ffffff !important;
}

.containerSomosInicioComponent {
  padding: 10px 55px 10px 70px !important;
  text-align: left !important;
}

.containerBottomInicioComponent {
  padding: 30px 100px 10px 100px !important;
}

.gridIconsInicioComponent {
  text-align: left !important;
}

.gridDirectionInicioComponent {
  text-align: left !important;
}

.gridTitle1InicioComponent {
  padding: 10px 55px 10px 70px !important;
  text-align: left !important;
}

.gridTitle2InicioComponent {
  padding: 10px 55px 10px 70px !important;
  text-align: left !important;
}

@media only screen and (max-width: 600px) {
  .title1InicioComponent {
    padding: 60px 0px 0px 0px !important;
    text-align: center !important;
  }
  .somosInicioComponent {
    text-align: center !important;
  }
  .containerSomosInicioComponent {
    padding: 20px !important;
    text-align: center !important;
  }
  .containerBottomInicioComponent {
    padding: 20px !important;
  }
  .gridIconsInicioComponent {
    text-align: center !important;
  }
  .gridDirectionInicioComponent {
    text-align: center !important;
  }
  .gridTitle1InicioComponent {
    padding: 10px !important;
    text-align: center !important;
  }
  .title2InicioComponent {
    text-align: center !important;
    padding: 5px !important;
    margin: auto !important;
  }
  .gridTitle2InicioComponent {
    padding: 10px !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .title1InicioComponent {
    padding: 60px 0px 0px 0px !important;
    text-align: center !important;
  }
  .containerBottomInicioComponent {
    padding: 10px !important;
  }
  .gridTitle1InicioComponent {
    padding: 10px !important;
    text-align: center !important;
  }
  .title2InicioComponent {
    text-align: center !important;
    padding: 5px !important;
    margin: auto !important;
  }
  .gridTitle2InicioComponent {
    padding: 10px !important;
  }
}

.containerNosotrosComponent {
  background-color: rgb(255 255 255) !important;
}

.tituloNosotrosComponent {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  text-align: left !important;
  padding: 20px 0px 20px 0px !important;
}

.subtituloNosotrosComponent {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  text-align: left !important;
  padding: 0px 0px 20px 0px !important;
}

.textoNosotrosComponent {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  text-align: left !important;
}

.containerTextoNosotrosComponent {
  padding: 70px 0px 0px 70px !important;
}

.containerImagenesNosotrosComponent {
  padding: 20px !important;
}

.containerImagenChicaNosotrosComponent {
  vertical-align: bottom !important;
  display: grid !important;
  align-items: end !important;
  justify-content: end !important;
}

.avatarNosotrosComponent {
  background: rgb(70 136 100) !important;
  width: 70px !important;
  height: 70px !important;
  margin: auto !important;
}

.iconNosotrosComponent {
  font-size: 50px !important;
}

.containerIconsNosotrosComponent {
  padding: 60px 30px 40px 30px !important;
}

.preguntaNosotrosComponent {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
}

.respuestaNosotrosComponent {
  font-family: inherit !important;
}
.cardNosotrosComponent {
  box-shadow: unset !important;
}

@media only screen and (max-width: 600px) {
  .textoNosotrosComponent {
    text-align: center !important;
  }
  .containerTextoNosotrosComponent {
    padding: 20px !important;
    text-align: center !important;
  }
  .containerImagenesNosotrosComponent {
    display: none !important;
  }
  .containerIconsNosotrosComponent {
    padding: 30px 30px 40px 30px !important;
  }

  .cardHeaderNosotrosComponent {
    text-align: center !important;
  }
  .tituloNosotrosComponent {
    text-align: center !important;
  }
  .subtituloNosotrosComponent {
    text-align: center !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .textoNosotrosComponent {
    text-align: center !important;
  }
  .containerTextoNosotrosComponent {
    padding: 20px !important;
    text-align: center !important;
  }
  .containerImagenesNosotrosComponent {
    display: none !important;
  }
  .containerIconsNosotrosComponent {
    padding: 30px 30px 40px 30px !important;
  }

  .cardHeaderNosotrosComponent {
    text-align: center !important;
  }
  .tituloNosotrosComponent {
    text-align: center !important;
  }
  .subtituloNosotrosComponent {
    text-align: center !important;
  }
}

.containerPromocionesInicioComponent {
  background-color: rgb(246 245 240) !important;
  padding: 20px !important;
}

.tituloPromocionesInicioComponent {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  text-align: left !important;
  padding: 20px 0px 10px 0px !important;
}

.subtituloPromocionesInicioComponent {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  text-align: left !important;
  padding: 0px 0px 20px 0px !important;
}

.containerTextoPromocionesInicioComponent {
  padding: 20px 70px !important;
}

.ofertaPromocionesInicioComponent {
  width: 350px !important;
  height: 350px !important;
  border-radius: 20px !important;
  box-shadow: 6px 8px 13px -6px rgb(0 0 0 / 75%) !important;
  -webkit-box-shadow: 6px 8px 13px -6px rgb(0 0 0 / 75%) !important;
  -moz-box-shadow: 6px 8px 13px -6px rgba(0, 0, 0, 0.75) !important;
}

@media only screen and (max-width: 600px) {
  .ofertaPromocionesInicioComponent {
    margin: 10px !important;
    width: 250px !important;
    height: 250px !important;
  }
  .tituloPromocionesInicioComponent {
    text-align: center !important;
  }
  .subtituloPromocionesInicioComponent {
    text-align: center !important;
  }
  .containerTextoPromocionesInicioComponent {
    padding: 20px !important;
  }
  .gridBotonAmarilloPromocionesInicioComponent {
    text-align: center !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .ofertaPromocionesInicioComponent {
    margin: 10px !important;
    width: 250px !important;
    height: 250px !important;
  }
  .tituloPromocionesInicioComponent {
    text-align: center !important;
  }
  .subtituloPromocionesInicioComponent {
    text-align: center !important;
  }
  .containerTextoPromocionesInicioComponent {
    padding: 20px !important;
  }
  .gridBotonAmarilloPromocionesInicioComponent {
    text-align: center !important;
  }
}

.root {
  flex-grow: 1 !important;
}

.containerProyectosComponent {
  background: rgb(246 245 240) !important;
}

.tituloProyectosComponent {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  text-align: center !important;
  padding: 20px 0px 10px 0px !important;
}

.subtituloProyectosComponent {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  text-align: center !important;
  padding: 0px 0px 20px 0px !important;
}

.avatar {
  width: 150px !important;
  height: 150px !important;
  margin: auto !important;
  border: 5px solid rgb(74 1 125) !important;
}

.nombreProyecto {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  text-align: center !important;
  padding: 10px !important;
}

.gridTituloNosotrosPage {
  padding: 100px 0px 150px 150px !important;
}
.gridSomosNosotrosPage {
  padding: 150px 115px 150px 0px !important;
}

.titleNosotrosPage {
  font-family: inherit !important;
  color: rgb(255 255 255) !important;
  font-weight: bold !important;
}

.subtitleNosotrosPage {
  font-family: inherit !important;
  color: rgb(255 255 255) !important;
}

.preguntaNosotrosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
  text-align: center !important;
  padding: 25px 0px 25px 0px !important;
}

.titleAzulNosotrosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
  text-align: center !important;
}

.somosNosotrosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  text-align: center !important;
  padding: 50px !important;
}

.slideNosotrosNosotrosPage {
  width: 60% !important;
  border-radius: 15px !important;
  box-shadow: 6px 8px 13px -6px rgb(0 0 0 / 75%) !important;
  -webkit-box-shadow: 6px 8px 13px -6px rgb(0 0 0 / 75%) !important;
  -moz-box-shadow: 6px 8px 13px -6px rgba(0 0 0 0.75) !important;
}

.gridMisionVisionTituloNosotrosPage {
  padding: 0px 0px 0px 180px !important;
}

.misionVisionTituloNosotrosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
  padding: 25px 0px 25px 0px !important;
}

.gridMisionVisionNosotrosPage {
  padding: 50px 150px 20px 100px !important;
}

.misionNosotrosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  padding: 0px 0px 20px 0px !important;
}

.visionNosotrosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
}

.gridPropuestaValorNosotrosPage {
  background-color: rgb(246 245 240) !important;
}

.logoAzulVerticalNosotrosPage {
  width: 50% !important;
  padding-top: 50px !important;
}

.nuestrosProyectosTituloNosotrosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
  text-align: center !important;
  padding: 50px !important;
}

.avatarNosotrosPage {
  width: 150px !important;
  height: 150px !important;
  margin: auto !important;
  border: 5px solid rgb(74 1 125) !important;
}

.nombreProyectoNosotrosPage {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  text-align: center !important;
  padding: 10px !important;
}

.descripcionProyectoNosotrosPage {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  text-align: center !important;
  padding: 10px !important;
  max-height: 300px !important;
  min-height: 100px !important;
}

.aNosotrosPage {
  text-decoration: none !important;
  color: rgb(255 255 255) !important;
}
.facebookIconNosotrosPage {
  color: rgb(255 170 0) !important;
  margin: 2px 5px 2px 5px !important;
  font-size: xx-large !important;
}

.instagramIconNosotrosPage {
  color: rgb(255 170 0) !important;
  margin: 2px 5px 2px 5px !important;
  font-size: xx-large !important;
}

@media only screen and (max-width: 600px) {
  .gridTituloNosotrosPage {
    padding: 50px 20px 20px 20px !important;
  }
  .gridSomosNosotrosPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .slideNosotrosNosotrosPage {
    width: 100% !important;
  }
  .gridMisionVisionTituloNosotrosPage {
    text-align: center !important;
    padding: 0px !important;
  }
  .misionVisionTituloNosotrosPage {
    padding: 20px !important;
  }
  .gridMisionVisionNosotrosPage {
    padding: 20px !important;
    text-align: center !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .gridTituloNosotrosPage {
    padding: 50px 20px 20px 20px !important;
  }
  .gridSomosNosotrosPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .slideNosotrosNosotrosPage {
    width: 100% !important;
  }
  .gridMisionVisionTituloNosotrosPage {
    text-align: center !important;
    padding: 0px !important;
  }
  .misionVisionTituloNosotrosPage {
    padding: 20px !important;
  }
  .gridMisionVisionNosotrosPage {
    padding: 20px !important;
    text-align: center !important;
  }
}

._404NotFound {
  text-align: center !important;
  margin: 100px 0px 0px 0px !important;
  font-size: 10rem !important;
  text-shadow: 3px 3px 0px rgb(15 210 161 1) !important;
  font-family: inherit !important;
}

.subtituloPageNotFound {
  text-align: center !important;
  margin: 0px 0px 20px 0px !important;
  font-family: inherit !important;
}

.textoPageNotFound {
  text-align: center !important;
  margin: 0px 0px 50px 0px !important;
  font-family: inherit !important;
}

.btnInicioPageNotFound {
  border: 1px solid rgb(0 0 0 1) !important;
  width: 250px !important;
  margin: auto !important;
  text-align: center !important;
  cursor: pointer !important;
  box-shadow: 3px 3px 0px 0px rgb(15 210 161 1);
  -webkit-box-shadow: 3px 3px 0px 0px rgb(15 210 161 1);
  -moz-box-shadow: 3px 3px 0px 0px rgb(15 210 161 1);
  font-family: inherit !important;
}

.divIconsPageNotFound {
  padding: 15px !important;
  margin: 15px auto 15px auto !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: inherit !important;
}

.divIconPageNotFound {
  width: 45px !important;
  height: 45px !important;
  border: 1px solid rgb(0 0 0 1) !important;
  margin: 5px 10px 5px 10px !important;
  padding: 5px !important;
  box-shadow: 3px 3px 0px 0px rgb(15 210 161 1);
  -webkit-box-shadow: 3px 3px 0px 0px rgb(15 210 161 1);
  -moz-box-shadow: 3px 3px 0px 0px rgb(15 210 161 1);
  font-family: inherit !important;
}

.iconPageNotFound {
  color: rgb(0 0 0 1) !important;
  font-family: inherit !important;
}

.aPageNotFound {
  text-decoration: none !important;
  color: rgb(0 0 0 1) !important;
  font-family: inherit !important;
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  ._404NotFound {
    margin: 50px 0px 0px 0px !important;
  }

  .btnInicioPageNotFound {
    margin: auto auto 50px auto !important;
  }
}

.gridTituloProyectosPage {
  padding: 100px 0px 150px 150px !important;
}
.tituloProyectosPage {
  font-family: inherit !important;
  color: #ffffff !important;
  font-weight: bold !important;
}

.gridSubtituloProyectosPage {
  padding: 150px 115px 150px 0px !important;
}

.subtituloProyectosPage {
  font-family: inherit !important;
  color: #ffffff !important;
}

.segundoGridProyectosPage {
  padding: 50px 100px 50px 150px !important;
  background: rgb(246 245 240);
}

.bridamosTextoProyectosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
}

.benficioProyectosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
}

.empresaProyectosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
}

.imagenTechoPropioProyectosPage {
  width: 60% !important;
  margin: auto !important;
}
.imagenCreditoMiViviendaProyectosPage {
  width: 60% !important;
  margin: auto !important;
}

.gridImagenesProyectosPage {
  text-align: center !important;
}

.gridProyectosProyectosPage {
  padding: 20px 50px 20px 50px !important;
  text-align: center !important;
}

.imagenProyectoProyectosPage {
  height: 120px !important;
  width: 120px !important;
  border: 5px solid rgb(74 1 125) !important;
  margin: auto auto -40px auto !important;
  background: rgb(255 255 255);
}

.divDescripcionProyecto {
  border: 1px solid rgb(0 0 0) !important;
  border-radius: 10px !important;
  min-height: 250px !important;
  max-height: 500px !important;
  width: 80%;
  margin: auto;
  padding-top: 30px !important;
}

.nombreProyectoProyectosPage {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  padding: 20px 0px 20px 0px !important;
  text-align: center !important;
}

.descripcionProyectoProyectosPage {
  color: rgb(0 0 0) !important;
  font-family: inherit !important;
  font-size: 16.5px !important;
  text-align: center !important;
  margin: 0px 10px 0px 10px !important;
}

.mapaProyectoProyectosPage {
  border: none !important;
  border-radius: 15px !important;
  width: 80% !important;
  margin: 20px 0px 40px 0px !important;
}

.facebookIconProyectosPage {
  font-size: 50px !important;
  color: rgb(74 1 125) !important;
}

.instagramIconProyectosPage {
  font-size: 50px !important;
  color: rgb(255 170 0) !important;
}

.roomIconProyectosPage {
  font-size: 30px !important;
  color: rgb(255 170 0) !important;
}

.phoneIconProyectosPage {
  font-size: 30px !important;
  color: rgb(255 170 0) !important;
}

.direccionProyectoProyectosPage {
  display: flex !important;
  font-size: 16px !important;
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: inherit !important;
  font-weight: bold !important;
}

.celularProyectoProyectosPage {
  display: flex !important;
  font-size: 16px !important;
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: inherit !important;
  font-weight: bold !important;
}

@media only screen and (max-width: 600px) {
  .gridTituloProyectosPage {
    padding: 50px 20px 20px 20px !important;
  }
  .gridSubtituloProyectosPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .segundoGridProyectosPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .divDescripcionProyecto {
    width: 90% !important;
    padding: 40px 10px 20px 10px !important;
  }
  .gridProyectosProyectosPage {
    padding: 5px !important;
  }
  .imagenProyectoProyectosPage {
    margin: 40px auto -40px auto !important;
  }
  .mapaProyectoProyectosPage {
    width: 95% !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .gridTituloProyectosPage {
    padding: 50px 20px 20px 20px !important;
  }
  .gridSubtituloProyectosPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .segundoGridProyectosPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .divDescripcionProyecto {
    width: 90% !important;
    padding: 40px 10px 20px 10px !important;
  }
  .gridProyectosProyectosPage {
    padding: 5px !important;
  }
  .imagenProyectoProyectosPage {
    margin: 40px auto -40px auto !important;
  }
  .mapaProyectoProyectosPage {
    width: 95% !important;
  }
}

.gridPromocionesPromocionesProyectoComponent {
  background: rgb(246 245 240) !important;
}

.tituloPromocionesProyectoComponent {
  text-align: center !important;
  padding: 20px !important;
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
}

.carousel {
  width: 80% !important;
  margin: 20px auto 20px auto !important;
}

.carousel button {
  background: rgb(255 170 0) !important;
}

.divImagenesPromocionPromocionesProyectoComponent {
  text-align: center !important;
  width: 80% !important;
  margin: auto !important;
  padding: 20px 100px 20px 100px !important;
}
.imagenPromocionPromocionesProyectoComponent {
  width: 50% !important;
  height: 500px !important;
  border-radius: 20px !important;
  margin: auto !important;
}

@media only screen and (max-width: 600px) {
  .carousel {
    width: 100% !important;
  }
  .divImagenesPromocionPromocionesProyectoComponent {
    padding: 20px !important;
  }
  .imagenPromocionPromocionesProyectoComponent {
    width: 100% !important;
    height: 400px !important;
    border-radius: 20px !important;
    margin: auto !important;
  }
}
@media only screen and (max-width: 900px) and (orientation: landscape) {
  .carousel {
    width: 100% !important;
  }
  .divImagenesPromocionPromocionesProyectoComponent {
    padding: 20px !important;
  }
  .imagenPromocionPromocionesProyectoComponent {
    width: 100% !important;
    height: 100% !important;
    border-radius: 20px !important;
    margin: auto !important;
  }
}

body {
  font-family: "Montserrat", sans-serif !important;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background: rgb(130 30 115);
}
::-webkit-scrollbar-track {
  background: #e1e1e1;
}
/* -------------------------------------------------------------- */

/* Botón amarillo */
.btn_amarillo {
  background-color: rgb(255 170 0) !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
  text-transform: none !important;
  text-transform: initial !important;
  font-family: unset !important;
  font-size: large !important;
  letter-spacing: 1.5px !important;
  border-radius: 10px !important;
  width: 150px !important;
  margin: 15px 0px 15px 0px !important;
}

.btn_amarillo:hover {
  background-color: rgb(74 1 125) !important;
  color: rgb(255 170 0) !important;
}

input {
  font-family: "Montserrat", sans-serif !important;
}

.ant-select-arrow {
  color: #670495 !important;
}

