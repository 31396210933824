.gridTituloAsesoresPage {
  padding: 100px 0px 150px 150px !important;
}

.tituloAsesoresPage {
  font-family: inherit !important;
  color: rgb(255 255 255);
  font-weight: bold !important;
}

.gridSubtituloAsesoresPage {
  padding: 150px 115px 150px 0px !important;
}

.subtituloAsesoresPage {
  font-family: inherit !important;
  color: rgb(255 255 255) !important;
}

.gridMotivacionTituloAsesoresPage {
  padding: 0px 0px 0px 180px !important;
}

.motivacionTituloAsesoresPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
  padding: 25px 0px 25px 0px !important;
}

.gridMotivacionAsesoresPage {
  padding: 50px 150px 20px 100px !important;
}

.motivacionAsesoresPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  padding: 0px 0px 20px 0px !important;
}

.containerIconsAsesoresPage {
  padding: 20px 100px 20px 100px;
}

.cardAsesoresPage {
  box-shadow: unset !important;
}

.iconAsesoresPage {
  width: 100px !important;
}

.nombreIconAsesoresPage {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
}

.containerAsesorAsesoresPage {
  width: 50% !important;
  margin: auto !important;
  padding: 20px !important;
}

.gridFotoAsesorAsesoresPage {
  padding: 0px 20px 0px 20px !important;
}

.fotoAsesorAsesoresPage {
  width: 100% !important;
  border-radius: 15px !important;
}

.nombreAsesorAsesoresPage {
  font-family: inherit !important;
  font-weight: bold !important;
  color: rgb(74 1 125) !important;
}

.cargoAsesorAsesoresPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
}

.proyectoAsignadoAsesorAsesoresPage {
  font-family: inherit !important;
  font-weight: bold !important;
  color: rgb(74 1 125) !important;
}

.datosContactoAsesorAsesoresPage {
  font-family: inherit !important;
  font-weight: bold !important;
  color: rgb(74 1 125) !important;
  font-size: 15px !important;
  display: flex !important;
}

.iconCelularAsesoresPage {
  color: rgb(255 170 0) !important;
  margin: 2px 5px 2px 5px !important;
  font-size: x-large !important;
}

.iconEmailAsesoresPage {
  color: rgb(130 30 115) !important;
  margin: 2px 5px 2px 5px !important;
  font-size: x-large !important;
}
.iconWhatsAppAsesoresPage {
  color: rgb(100 177 97) !important;
  margin: 2px 5px 2px 5px !important;
  font-size: x-large !important;
}

.aAsesoresPage {
  text-decoration: none !important;
  color: rgb(74 1 125) !important;
  display: flex !important;
}

.containerFinalAsesoresPage {
  background: rgb(246 245 240);
}

.gridTextoFinalAsesoresPage {
  padding: 50px 20px 20px 200px !important;
}

.textoFinalAsesoresPage {
  font-family: inherit !important;
  font-weight: bold !important;
  color: rgb(74 1 125) !important;
}

.imageFinalAsesoresPage {
  width: 80% !important;
  margin: auto !important;
  border-radius: 15px !important;
}

.containerImageFinalAsesoresPage {
  text-align: center !important;
  padding: 50px !important;
}

@media only screen and (max-width: 600px) {
  .gridTituloAsesoresPage {
    padding: 50px 20px 20px 20px !important;
  }
  .gridSubtituloAsesoresPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .gridMotivacionTituloAsesoresPage {
    text-align: center !important;
    padding: 0px !important;
  }
  .motivacionTituloAsesoresPage {
    padding: 20px !important;
  }
  .gridMotivacionAsesoresPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .containerIconsAsesoresPage {
    padding: 5px !important;
  }
  .cardHeaderAsesoresPage {
    text-align: center !important;
  }
  .containerAsesorAsesoresPage {
    width: 100% !important;
    margin: auto !important;
    padding: 20px !important;
  }
  .gridFotoAsesorAsesoresPage {
    padding: 0px !important;
  }
  .gridDatosAsesorAsesoresPage {
    text-align: center !important;
  }
  .containerFinalAsesoresPage {
    padding: 20px !important;
  }
  .gridTextoFinalAsesoresPage {
    padding: 10px !important;
    text-align: center !important;
  }
  .containerImageFinalAsesoresPage {
    padding: 5px !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .gridTituloAsesoresPage {
    padding: 50px 20px 20px 20px !important;
  }
  .gridSubtituloAsesoresPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .gridMotivacionTituloAsesoresPage {
    text-align: center !important;
    padding: 0px !important;
  }
  .motivacionTituloAsesoresPage {
    padding: 20px !important;
  }
  .gridMotivacionAsesoresPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .containerIconsAsesoresPage {
    padding: 20px 50px 20px 50px !important;
  }
  .cardHeaderAsesoresPage {
    text-align: center !important;
  }
  .containerAsesorAsesoresPage {
    width: 100% !important;
    margin: auto !important;
    padding: 20px !important;
  }
  .gridFotoAsesorAsesoresPage {
    padding: 0px !important;
  }
  .gridDatosAsesorAsesoresPage {
    text-align: center !important;
  }
  .containerFinalAsesoresPage {
    padding: 20px !important;
  }
  .gridTextoFinalAsesoresPage {
    padding: 10px !important;
    text-align: center !important;
  }
  .containerImageFinalAsesoresPage {
    padding: 5px !important;
  }
}
