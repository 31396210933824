.grow {
  flex-grow: 1;
}

.appbar {
  background-color: transparent !important;
  box-shadow: unset !important;
  padding: 20px 50px 20px 200px !important;
}

.logo {
  width: 20% !important;
}

.item {
  margin: 0px 15px 0px 15px !important;
  font-family: unset !important;
}
a {
  text-decoration: none !important;
  color: #ffffff !important;
}

.aMovil {
  text-decoration: none !important;
  color: #000000 !important;
  font-weight: bold !important;
  font-family: Montserrat !important;
}

.number {
  color: rgb(255 170 0) !important;
  margin: 0px 15px 0px 15px !important;
  font-family: unset !important;
  font-weight: bold !important;
}

@media only screen and (max-width: 600px) {
  .appbar {
    padding: unset !important;
    position: fixed !important;
    background-color: rgb(0 0 0 / 80%) !important;
  }
  .logo {
    width: 10% !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .appbar {
    padding: unset !important;
    position: fixed !important;
    background-color: rgb(0 0 0 / 80%) !important;
  }
}
