.containerFooterComponent {
  background-color: rgb(74 1 125) !important;
  padding: 25px 50px 0px 200px !important;
}

.logoFooterComponent {
  width: 300px !important;
  margin: 15px !important;
}

.datosFooterComponent {
  font-family: inherit !important;
  color: rgb(255 255 255) !important;
  display: flex !important;
  font-weight: bold !important;
  font-size: 15px !important;
  align-items: center !important;
}

.tituloFooterComponent {
  font-family: inherit !important;
  color: rgb(255 255 255) !important;
  text-align: left !important;
  display: flex !important;
  font-weight: bold !important;
  padding-bottom: 40px !important;
}
 
.iconsFooterComponent {
  color: rgb(255 170 0) !important;
  margin: 2px 5px 2px 5px !important;
  font-size: xx-large !important;
}

.aFooterComponent {
  text-decoration: none !important;
  color: rgb(255 255 255);
}

.enlacesFooterComponent {
  font-size: 18px !important;
  color: rgb(255 255 255) !important;
  font-family: inherit !important;
}

.containerIzquierdaFooterComponent {
  padding: 20px !important;
}

.containerCentroFooterComponent {
  padding: 40px 0px !important;
}

.containerDerechaFooterComponent {
  padding: 40px 0px !important;
}

.libroFooterComponent {
  width: 100% !important;
}

.libroTextoFooterComponent {
  font-family: inherit !important;
  color: rgb(225 255 255) !important;
  text-align: center !important;
  display: flex !important;
  font-weight: bold !important;
  font-size: 12px !important;
}

.leyFooterComponent {
  font-family: inherit !important;
  text-align: center !important;
  display: flex !important;
  font-size: 10px !important;
  color: rgb(255 170 0) !important;
}

.containerLibroFooterComponent {
  vertical-align: bottom !important;
  display: grid !important;
  align-items: end !important;
  justify-content: end !important;
  padding-bottom: 45px !important;
}

@media only screen and (max-width: 600px) {
  .containerFooterComponent {
    padding: 10px !important;
  }
  .logoFooterComponent {
    width: 90% !important;
  }
  .libroFooterComponent {
    width: 50% !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .containerFooterComponent {
    padding: 10px !important;
  }
  .logoFooterComponent {
    width: 90% !important;
  }
  .libroFooterComponent {
    width: 50% !important;
  }
}
