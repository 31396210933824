.gridTituloProyectosPage {
  padding: 100px 0px 150px 150px !important;
}
.tituloProyectosPage {
  font-family: inherit !important;
  color: #ffffff !important;
  font-weight: bold !important;
}

.gridSubtituloProyectosPage {
  padding: 150px 115px 150px 0px !important;
}

.subtituloProyectosPage {
  font-family: inherit !important;
  color: #ffffff !important;
}

.segundoGridProyectosPage {
  padding: 50px 100px 50px 150px !important;
  background: rgb(246 245 240);
}

.bridamosTextoProyectosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
}

.benficioProyectosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
}

.empresaProyectosPage {
  font-family: inherit !important;
  color: rgb(74 1 125) !important;
  font-weight: bold !important;
}

.imagenTechoPropioProyectosPage {
  width: 60% !important;
  margin: auto !important;
}
.imagenCreditoMiViviendaProyectosPage {
  width: 60% !important;
  margin: auto !important;
}

.gridImagenesProyectosPage {
  text-align: center !important;
}

.gridProyectosProyectosPage {
  padding: 20px 50px 20px 50px !important;
  text-align: center !important;
}

.imagenProyectoProyectosPage {
  height: 120px !important;
  width: 120px !important;
  border: 5px solid rgb(74 1 125) !important;
  margin: auto auto -40px auto !important;
  background: rgb(255 255 255);
}

.divDescripcionProyecto {
  border: 1px solid rgb(0 0 0) !important;
  border-radius: 10px !important;
  min-height: 250px !important;
  max-height: 500px !important;
  width: 80%;
  margin: auto;
  padding-top: 30px !important;
}

.nombreProyectoProyectosPage {
  color: rgb(74 1 125) !important;
  font-family: inherit !important;
  font-weight: bold !important;
  padding: 20px 0px 20px 0px !important;
  text-align: center !important;
}

.descripcionProyectoProyectosPage {
  color: rgb(0 0 0) !important;
  font-family: inherit !important;
  font-size: 16.5px !important;
  text-align: center !important;
  margin: 0px 10px 0px 10px !important;
}

.mapaProyectoProyectosPage {
  border: none !important;
  border-radius: 15px !important;
  width: 80% !important;
  margin: 20px 0px 40px 0px !important;
}

.facebookIconProyectosPage {
  font-size: 50px !important;
  color: rgb(74 1 125) !important;
}

.instagramIconProyectosPage {
  font-size: 50px !important;
  color: rgb(255 170 0) !important;
}

.roomIconProyectosPage {
  font-size: 30px !important;
  color: rgb(255 170 0) !important;
}

.phoneIconProyectosPage {
  font-size: 30px !important;
  color: rgb(255 170 0) !important;
}

.direccionProyectoProyectosPage {
  display: flex !important;
  font-size: 16px !important;
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: inherit !important;
  font-weight: bold !important;
}

.celularProyectoProyectosPage {
  display: flex !important;
  font-size: 16px !important;
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: inherit !important;
  font-weight: bold !important;
}

@media only screen and (max-width: 600px) {
  .gridTituloProyectosPage {
    padding: 50px 20px 20px 20px !important;
  }
  .gridSubtituloProyectosPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .segundoGridProyectosPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .divDescripcionProyecto {
    width: 90% !important;
    padding: 40px 10px 20px 10px !important;
  }
  .gridProyectosProyectosPage {
    padding: 5px !important;
  }
  .imagenProyectoProyectosPage {
    margin: 40px auto -40px auto !important;
  }
  .mapaProyectoProyectosPage {
    width: 95% !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .gridTituloProyectosPage {
    padding: 50px 20px 20px 20px !important;
  }
  .gridSubtituloProyectosPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .segundoGridProyectosPage {
    padding: 20px !important;
    text-align: center !important;
  }
  .divDescripcionProyecto {
    width: 90% !important;
    padding: 40px 10px 20px 10px !important;
  }
  .gridProyectosProyectosPage {
    padding: 5px !important;
  }
  .imagenProyectoProyectosPage {
    margin: 40px auto -40px auto !important;
  }
  .mapaProyectoProyectosPage {
    width: 95% !important;
  }
}
